function sendGA4Event(event, category, label, chatId, botId) {
  const BOT_TO_TAG = {
    '672a1446e5f4c244b3f14ad3': 'G-ZJQBXWRT63',
    '658301729b9f28d8954b5895': 'G-7MJRQFD2PG',
    // Add more botId -> tagId pairs here if needed
  }

  const GA4_GROUP_NAME = 'noform_ai_ga4'

  if (!BOT_TO_TAG[botId]) {
    return
  }

  // define gtag function if needed
  if (typeof window !== 'undefined' && !window.gtag) {
    window.dataLayer = window.dataLayer || []
    window.gtag = function gtag() {
      window.dataLayer.push(arguments)
    }
  }

  // window.gtag('config', BOT_TO_TAG[botId], {
  //   groups: GA4_GROUP_NAME,
  //   send_page_view: false,
  // })

  window.gtag('event', event, {
    event_category: category,
    event_label: label,
    value: chatId,
    send_to: GA4_GROUP_NAME,
  })
}

export default sendGA4Event
